import React from 'react';
import { Box, Flex } from '@3pc/layout-components-react';
import { Text, Link, Image } from 'src/elements';
import { ArrowDiagonal } from 'src/icons';
import { optIn, optOut, getOptOut } from 'src/matomo';
import { getYoutubeConsent, youtubeOptIn, youtubeOptOut } from 'src/youtube';

const Imprint = () => {
  const [userOptOut, setUserOptOut] = React.useState(getOptOut() === 'true');
  const [youtube, setYoutube] = React.useState(getYoutubeConsent() === 'true');

  React.useEffect(() => {
    if (userOptOut) optOut();
    else optIn();
  }, [userOptOut]);

  React.useEffect(() => {
    if (youtube) youtubeOptIn();
    else youtubeOptOut();
  }, [youtube]);

  React.useEffect(() => {
    document.title = 'Impressum | Klassik Stiftung Weimar';
  }, []);

  return (
    <Flex pt="28" flexDirection="column" alignItems="center">
      <Box px={['10', null, '0']} maxWidth={['100%', null, '435px', '600px']}>
        <Text
          as="h1"
          fontSize={['xlarge', null, 'xxlarge', 'xxxlarge']}
          textAlign="center"
          textTransform="uppercase"
          wordWrap="break-word"
        >
          Impressum
        </Text>
        <Section mt={['10', null, '16']}>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Anbieter
          </Text>
          <Box mt="4">
            <P>Klassik Stiftung Weimar</P>
            <P>Stiftung des öffentlichen Rechts</P>
            <P>Burgplatz 4</P>
            <P>99423 Weimar</P>
          </Box>
          <Box mt="4">
            <P>Tel.: +49 (0) 3643-545-0</P>
            <Flex alignItems="flex-start">
              <P lineHeight="26px">E-Mail:</P>
              <Box ml="1">
                <Link href="mailto:poststelle@klassik-stiftung.de">
                  <P color="#00e" lineHeight="26px">
                    poststelle@klassik-stiftung.de
                  </P>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Vertretungsberechtigte
          </Text>
          <Box mt="4">
            <P>
              Die Klassik Stiftung Weimar wird gesetzlich vertreten durch ihre
              Präsidentin, Frau Dr. Ulrike Lorenz.
            </P>
          </Box>
          <Box mt="4">
            <P>
              Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz
              (VAT) DE 150125730
            </P>
          </Box>
          <Box mt="4">
            <P>Bankverbindung</P>
            <P>Sparkasse Mittelthüringen</P>
            <P>BIC: HELADEF1WEM</P>
            <P>IBAN: DE08 8205 1000 0301 0230 00</P>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Inhaltlich Verantwortlicher
          </Text>
          <Box mt="4">
            <P>Rainer Engelhardt</P>
          </Box>
          <Box mt="4">
            <P>Referatsleiter Kommunikation und Marketing</P>
            <P>Stabsreferat Kommunikation, Öffentlichkeitsarbeit, Marketing</P>
            <P>Klassik Stiftung Weimar</P>
            <P>Burgplatz 4</P>
            <P>99423 Weimar</P>
          </Box>
          <Box mt="4">
            <P>Tel. +49-(0) 3643-545-104</P>
            <Flex alignItems="flex-start">
              <P lineHeight="26px">E-Mail:</P>
              <Box ml="1">
                <Link href="mailto:Rainer.Engelhardt@klassik-stiftung.de">
                  <P color="#3D00FF" lineHeight="26px">
                    Rainer.Engelhardt@klassik-stiftung.de
                  </P>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Gestaltung und technische Umsetzung
          </Text>
          <Box mt="5">
            <Link href="https://3pc.de/" style={{ lineHeight: 1 }}>
              <Flex alignItems="center" color="primary.0">
                <P capsize={true}>3pc GmbH Neue Kommunikation</P>
                <ArrowDiagonal ml="2" />
              </Flex>
            </Link>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Rechtshinweise
          </Text>
          <Box mt="6">
            <Text as="h3" size="xsmall" capsize={false}>
              Urheberrechte
            </Text>
            <Box mt="4">
              <P>
                Alle Texte, Bilder, Graphiken, Ton-, Video- und
                Animationsdateien sowie deren Anordnung auf den Websites der
                Klassik Stiftung Weimar unterliegen dem Urheberrecht und anderen
                Gesetzen zum Schutz geistigen Eigentums. Sie dürfen weder für
                kommerzielle Zwecke oder zur Weitergabe kopiert noch verändert
                und auf anderen Websites verwendet werden. Davon ausgenommen ist
                das unter den Rubriken »Presse« für die Weiterverbreitung
                ausdrücklich angebotene Text- und Bildmaterial. Wir weisen
                darauf hin, dass einige Seiten der Websites der Klassik Stiftung
                Weimar auch Bilder enthalten, die dem Urheberrecht derjenigen
                unterliegen, die diese zur Verfügung gestellt haben.
              </P>
            </Box>
          </Box>
          <Box mt="6">
            <Text as="h3" size="xsmall" capsize={false}>
              Bildnachweis
            </Text>
            <Box mt="4">
              <P>
                Das Bildmaterial ist urheberrechtlich geschützt. Die Rechte
                liegen, sofern nicht anders gekennzeichnet, bei der Klassik
                Stiftung Weimar.
              </P>
            </Box>
            <Box mt="4">
              <P>
                Der Anbieter bemüht sich intensiv, alle Inhaber von
                Abbildungsrechten ausfindig zu machen. Personen und
                Institutionen, die möglicherweise nicht ermittelt werden
                konnten, bitten wir um Nachricht.
              </P>
            </Box>
          </Box>
          <Box mt="6">
            <Text as="h3" size="xsmall" capsize={false}>
              Fotografen
            </Text>
            <Box mt="4">
              <P>Alexander Burzik</P>
              <P>Thomas Müller</P>
              <P>Candy Welz</P>
              <P>Jens Hauspurg</P>
            </Box>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Haftung
          </Text>
          <Box mt="4">
            <P>
              Alle Angaben dieses Internetangebotes wurden sorgfältig geprüft.
              Wir bemühen uns, dieses Informationsangebot stetig zu erweitern
              und zu aktualisieren. Eine Garantie für die Vollständigkeit,
              Richtigkeit und letzte Aktualität kann jedoch nicht übernommen
              werden. Die Klassik Stiftung Weimar stellt diese Informationen
              ohne jegliche Zusicherung oder Gewährleistung jedweder Art, sei
              sie ausdrücklich oder stillschweigend, zur Verfügung. Die Klassik
              Stiftung Weimar schließt jegliche Haftung für Schäden, die direkt
              oder indirekt aus der Benutzung dieser Website entstehen, aus,
              soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit der
              Klassik Stiftung Weimar beruhen.
            </P>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Verweise und Links
          </Text>
          <Box mt="4">
            <P>
              Bei direkten oder indirekten Verweisen auf fremde Internetseiten
              (»Links«), die außerhalb des Verantwortungsbereiches der Klassik
              Stiftung Weimar liegen, würde eine Haftungsverpflichtung
              ausschließlich in dem Fall in Kraft treten, in dem die Klassik
              Stiftung Weimar von den Inhalten Kenntnis hat und es ihr technisch
              möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger
              Inhalte zu verhindern. Die Klassik Stiftung Weimar erklärt hiermit
              ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen
              Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die
              aktuelle und zukünftige Gestaltung, die Inhalte oder die
              Urheberschaft der gelinkten/verknüpften Seiten hat die Klassik
              Stiftung Weimar keinerlei Einfluss. Deshalb distanziert sie sich
              hiermit ausdrücklich von allen Inhalten aller
              gelinkten/verknüpften Seiten, die nach der Linksetzung verändert
              wurden. Diese Feststellung gilt für alle innerhalb des eigenen
              Internetangebotes gesetzten Links und Verweise sowie für
              Fremdeinträge in von der Klassik Stiftung Weimar eingerichteten
              Gästebüchern, Diskussionsforen und Mailinglisten. Für illegale,
              fehlerhafte oder unvollständige Inhalte und insbesondere für
              Schäden, die aus der Nutzung oder Nichtnutzung solcherart
              dargebotener Informationen entstehen, haftet allein der Anbieter
              der Seite, auf welche verwiesen wurde, nicht derjenige, der über
              Links auf die jeweilige Veröffentlichung lediglich verweist.
            </P>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Lizenz
          </Text>
          <Box mt="4">
            <P>
              Die Klassik Stiftung Weimar möchte sich Ihnen mit einer
              innovativen und informativen Website präsentieren. Das darin
              enthaltene geistige Eigentum wie Patente, Marken und Urheberrechte
              ist geschützt. Durch diese Website wird keine Lizenz zur Nutzung
              des geistigen Eigentums der Klassik Stiftung Weimar oder Dritten
              erteilt.
            </P>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Rechtswahl
          </Text>
          <Box mt="4">
            <P>
              Sämtliche Informationen oder Daten, die Nutzung der Klassik
              Stiftung Weimar Website sowie sämtliches mit der Klassik Stiftung
              Weimar Website zusammenhängendes Tun, Dulden oder Unterlassen
              unterliegen ausschließlich deutschem Recht. Erfüllungsort und
              ausschließlicher Gerichtsstand ist Weimar.
            </P>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Korruptionsprävention
          </Text>
          <Box mt="4">
            <P>
              Korruption untergräbt das Vertrauen in die Integrität und
              Funktionsfähigkeit der öffentlichen Verwaltung. Die Klassik
              Stiftung Weimar, als Stiftung des öffentlichen Rechts, ist stets
              bestrebt, integer, uneigennützig und transparent zu handeln. Aus
              diesem Grund treten wir der Korruption mit den Mitteln der
              Kontrolle, Aufdeckung und Sanktion entschieden entgegen.
            </P>
          </Box>
          <Box mt="4">
            <P>
              In Übereinstimmung mit der Richtlinie zur Korruptionsbekämpfung in
              der öffentlichen Verwaltung des Freistaats Thüringen hat die
              Klassik Stiftung Weimar einen Antikorruptionsbeauftragten
              bestellt. Bei Fragen der Korruptionsprävention oder Hinweisen auf
              einen möglichen Korruptionsverdacht erreichen Sie unseren
              Antikorruptionsbeauftragten Herrn Peter Dähne unter
            </P>
          </Box>
          <Box mt="4">
            <Link
              href="mailto:antikorruptionsbeauftragter@klassik-stiftung.de"
              style={{ lineHeight: 1 }}
            >
              <P color="#3D00FF">
                antikorruptionsbeauftragter@klassik-stiftung.de
              </P>
            </Link>
          </Box>
        </Section>
      </Box>
      <Section>
        <Box px={['10', null, '0']} maxWidth={['100%', null, '435px', '600px']}>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Förderung
          </Text>
        </Box>
        <Flex
          alignItems="center"
          mt="4"
          px={['2', '10', '0']}
          maxWidth={['100%', null, '435px', '600px']}
        >
          <Box flex="1 0 0" py="2">
            <Link href="https://www.staatskanzlei-thueringen.de/ ">
              <Image
                width="100%"
                src="/images/logo_tsk.jpg"
                webpSrc="/images/logo_tsk.webp"
                alt="Freistaat Thüringen Staatskanzlei"
              />
            </Link>
          </Box>
          <Box flex="1 0 0" py="2" ml="1">
            <Link href="http://www.kulturstaatsministerin.de">
              <Image
                width="100%"
                src="/images/logo_bkm.png"
                webpSrc="/images/logo_bkm.webp"
                alt="Die Beauftragte der Bundesregierung für Kultur und Medien"
              />
            </Link>
          </Box>
        </Flex>
      </Section>
      <Box
        px={['10', null, '0']}
        maxWidth={['100%', null, '435px', '600px']}
        pb="10"
      >
        <Section id="youtube-terms">
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Youtube Nutzungsbedingungen
          </Text>
          <Box mt="4">
            <P>
              Hinweis: Wenn Sie die eingebetteten Youtube Videos auf dieser
              Seite ansehen möchten müssen sie den{' '}
              <a href="https://www.youtube.com/t/terms">
                Nutzungsbedingungen von Youtube
              </a>{' '}
              zustimmen. Dadurch werden personenbezogene Daten (IP-Adresse) an
              den Betreiber des Videoportals gesendet. Daher ist es möglich,
              dass der Videoanbieter Ihre Zugriffe speichert und ihr Verhalten
              analysieren kann.
            </P>
          </Box>
          <Box mt="4">
            <Flex alignItems="center" as="label">
              <P capsize={true}>Youtubes Nutzungsbedingungen zustimmen</P>
              <Box
                ml="5"
                as="input"
                type="checkbox"
                checked={youtube}
                onChange={event => setYoutube(event.currentTarget.checked)}
              />
            </Flex>
          </Box>
        </Section>
        <Section>
          <Text
            as="h2"
            wordWrap="break-word"
            fontSize={['large', null, null, 'xlarge']}
            capsize={false}
          >
            Datenschutzerklärung
          </Text>
          <Box mt="6">
            <Text as="h3" size="xsmall" capsize={false}>
              Reichweitenmessung mit Matomo
            </Text>
            <Box mt="4">
              <P>
                Im Rahmen der Reichweitenanalyse von Matomo werden auf Grundlage
                unserer berechtigten Interessen (d.h. Interesse an der Analyse,
                Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes
                im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) die folgenden Daten
                verarbeitet: der von den Nutzern verwendete Browsertyp und die
                Browserversion, das von ihnen verwendete Betriebssystem, ihr
                Herkunftsland, Datum und Uhrzeit der Serveranfrage, die Anzahl
                der Besuche, ihre Verweildauer auf der Website sowie die von
                ihnen betätigten externen Links. Die IP-Adresse der Nutzer wird
                anonymisiert, bevor sie gespeichert wird. Matomo verwendet
                Cookies, die auf dem Computer der Nutzer gespeichert werden und
                die eine Analyse der Benutzung unseres Onlineangebotes durch die
                Nutzer ermöglichen. Dabei können aus den verarbeiteten Daten
                pseudonyme Nutzungsprofile der Nutzer erstellt werden. Die
                Cookies haben eine Speicherdauer von einer Woche. Die durch das
                Cookie erzeugten Informationen über Ihre Benutzung dieser
                Webseite werden nur auf unserem Server gespeichert und nicht an
                Dritte weitergegeben. Nutzer können der anonymisierten
                Datenerhebung durch das Programm Matomo jederzeit mit Wirkung
                für die Zukunft widersprechen, indem sie auf den untenstehenden
                Link klicken. In diesem Fall wird in ihrem Browser ein sog.
                Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo
                keinerlei Sitzungsdaten mehr erhebt. Wenn Nutzer ihre Cookies
                löschen, so hat dies jedoch zur Folge, dass auch das
                Opt-Out-Cookie gelöscht wird und daher von den Nutzern erneut
                aktiviert werden muss. Die Logs mit den Daten der Nutzer werden
                nach spätestens 6 Monaten gelöscht.
              </P>
            </Box>
            <Box mt="4">
              <Flex alignItems="center" as="label">
                <P capsize={true}>Matomo Tracking</P>
                <Box
                  ml="5"
                  as="input"
                  type="checkbox"
                  checked={!userOptOut}
                  onChange={event =>
                    setUserOptOut(!event.currentTarget.checked)
                  }
                />
              </Flex>
            </Box>
          </Box>
        </Section>
      </Box>
    </Flex>
  );
};

const P = ({ children, ...props }) => (
  <Text size="xxsmall" capsize={false} {...props}>
    {children}
  </Text>
);

const Section = ({ ...props }) => <Box mt="8" as="section" {...props} />;

export default Imprint;
